<script>
  import { onMount } from "svelte";

  import Nested from "./Nested.svelte";
  import Card from "./Card.svelte";
</script>

<style>
  p {
    position: fixed;
    color: #111111;
    bottom: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: bolder;
  }

  a {
    color: #1fbad6;
    font-weight: bold;
  }
</style>

<main>
  <!-- <Nested />
  <Card /> -->
  <p>
    For more information visit
    <a href="https://sight.plus" target="_blank">https://sight.plus</a>
    or email jonathan@weld.vision
  </p>
</main>
