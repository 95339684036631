<style>
  .card {
    height: 100px;
    width: 250px;
    background: #333;
    border-radius: 15px;
  }
</style>

<div class="card">This is a card</div>
